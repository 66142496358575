@charset "UTF-8";

.circular-nav {
  width: vwRel($cnwidth);
  height: vwRel($cnwidth);
  max-width: px($cnwidth);
  max-height: px($cnwidth);
  min-width: px(500);
  min-height: px(500);
  transform: translateX(-15%);

  @include sView() {
    right: calc(500px - 68vw);
  }

  @include maxView(590) {
    right: calc(#{vhRel($smallHeightCNwidth)} - 74vw);
  }

  @include maxHeight($screenHeightBreakPoint) {
    width: vhRel($smallHeightCNwidth);
    height: vhRel($smallHeightCNwidth);
    max-width: vhRel($smallHeightCNwidth);
    max-height: vhRel($smallHeightCNwidth);
    min-width: px(350);
    min-height: px(350);
  }

  @media screen and (max-width: 590px) and(max-height: 678px) {
    //right: calc(350px - 55vw);
    right: calc(350px - 45vw);
  }


  video {
    min-height: vwRel(540);
    max-height: vwRel(540);

    @include minView(1127) {
      min-height: px(520);
      max-height: px(520);
    }

    @include sView() {
      min-height: px(410);
      max-height: px(410);
    }
  }

  .links {
    width: px(200);

    @include maxHeight($screenHeightBreakPoint) {
      width: vhRel(200);
    }

    &> a {
      font-size: px($titleSize);

      @include maxHeight($screenHeightBreakPoint) {
        font-size: vhRel($titleSize);
      }
    }
  }

  .subnav li {
    padding: px(5) 0 px(5) px(7);

    @include maxHeight($screenHeightBreakPoint) {
      font-size: vhRel($textSize);
      padding: vhRel(5) 0 vhRel(5) vhRel(7);
    }
  }
}


.info-wrapper {
  width: calc(99% - #{px($cnwidth + 100)});
  max-width: px(800);
  height: px($cnwidth);
  margin-left: px($cnwidth + 110);
  min-height: px(350);
  //margin-left: px($cnwidth + 110);

  @media screen and (max-aspect-ratio: 11/10) {
    display: none;
  }

  @include maxHeight($screenHeightBreakPoint) {
    height: vhRel($smallHeightCNwidth + 80);
    margin-left: vhRel($smallHeightCNwidth + 80);
    width: calc(90vw - #{vhRel($smallHeightCNwidth)});
    max-width: px(650);

    .info {
      padding-left: px(35);
    }
  }

  @include maxHeight(540) {
    margin-left: px(395);
    max-width: 40vw;
  }

  @include minView(1000) {
    display: block;
  }

  @media screen and (max-width: 735px) and (max-height: 540px) {
    margin-left:  50vw;
  }

  .title {
    font-size: vwRel(20);

    @include maxView(600) {
      font-size: vwRelSmall(11);
    }
  }

}

#home .footer-logo {
  position: fixed;
  width: px($footerLogoLargeWidth);
  height: px(0.76 * $footerLogoLargeWidth);
  bottom: 0;
  right: 0;

  @include sView() {
    width: vwRelSmall(100);
    height: vwRelSmall(0.76 * 100);
  }

  @include maxHeight($screenHeightBreakPoint) {
    width: vhRel(120);
    height: vhRel(0.76 * 120);
  }
}

