@charset "UTF-8";

.circular-nav {
  position: relative;
  transform: translateX(-15%);

  .circles-wrapper {
    transition: trans(transform, 700);
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
  }

  .svg-circle {
    max-width: 100%;
    max-height: 100%;

    circle {
      transition: trans(stroke-width);
      cursor: pointer;

      &.hidden {
        opacity: 0;
      }
    }
  }

  .video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    border-radius: 50%;
    background-color: #3E7292;
    z-index: 1;
    overflow: hidden;
    pointer-events: none;
  }

  &.initialized {
    .video-wrapper {
      opacity: 1;
    }
  }

  .video-wrapper {
    opacity: 0;
    transition: trans(opacity, 700);
    transition-delay: 300ms;

    &:after {
      content: '';
      position: absolute;
      background-color: inherit;
      transition: trans(background-color);
      opacity: 0.3;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
    }

    video {
      position: absolute;
      height: auto;
      width: auto;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }

  .c-text {
    position: absolute;
    display:none;
    @include flexBoxes(center, center);
    color: $white;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    border-radius: 50%;
    pointer-events: none;
    opacity: 0;
    transition: transs(opacity transform, 700);

    &.visible {
      opacity: 1;

      .nav-wrapper {
        transform: translateY(-50%) translateX(0);
      }
    }

    .axis {
      width: 100%;
      height: 1px;
      border-radius: 50%;
    }

    .nav-wrapper {
      position: absolute;
      height: 0;
      width: px(100);
      border-top: 1px solid white;
      max-height: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%) translateX(-200%);
      transition: trans(transform);

      &:before {
        content: '';
        position: absolute;
        width: 4px;
        height: 4px;
        top: 50%;
        right: 0;
        transform: translateY(-50%) translateX(50%);
        background-color: $white;
        border-radius: 50%;
      }

      .links {
        position: absolute;
        display: block;
        margin: 0;
        padding-top: px(2);
        bottom: 0;
        left: 100%;
        transform: translateY(100%);
        pointer-events: auto;
        text-decoration: none;
        cursor: pointer;

        &>a {
          display: block;
          text-decoration: none;
        }

        &>span {
          display: block;
          position: relative;
          z-index: 1;
        }

        &:before {
          content: '';
          position: absolute;
          display: block;
          height: px(50);
          width: 100%;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
      }
    }

    .subnav {
      position: absolute;
      padding: px(5) 0 px(7) 0;
      max-height: 0;
      max-width: 0;
      overflow: hidden;

      li {

        a {
          display: block;
          position: relative;
          text-decoration: none;
          transform: translateY(-100%);
          opacity: 0;
          transition: transs(opacity transform color);
          padding-left: px(15);
        }
      }
    }

    &:not(.active) .links:hover {
      &>a {
        color: $white;
      }
    }

    &.active {

      .subnav {
        max-height: none;
        max-width: none;

        li a {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }
}
