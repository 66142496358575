@charset "UTF-8";

@import "../settings";
@import "../functions";
@import "../mixins";
@import "circular-nav";
@import "home_responsivity";

body#home:not(.editmode) {
  user-select: none;

  .info {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
    pointer-events: none;
    visibility: hidden;


    &.initialized {
      visibility: visible;

    }


    &.visible {
      pointer-events: auto;

      .title {
        div {

          .letter {
            transition: transs(transform opacity, 400);
          }

          .letter.show {
            transform: translateY(0) translateY(0) !important;
            opacity: 1;
          }
        }
      }

      .link-label {
        opacity: 1;
      }
    }

    .title {

      div {
        position: relative;

        .letter {
          position: relative;
          opacity: 0;

          &.transition {
            transition: transs(transform opacity, 0);
          }
        }
      }
    }

    .link-label {
      opacity: 0;
      transition: trans(opacity, 700);
    }
  }

}

body#home {
  background-color: #3c273c;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: px($headerHeight);

  @include maxView(400) {
    max-width: 100vw;
  }

  #home-wrapper {
    position: absolute;

    @include sView() {
      max-width: 100%;
    }
  }

  svg {
    .fill-black, .fill-red {
      fill: $white;
    }
  }

  .white-bg {
    display: none;
  }
}

.info-wrapper {
  position: relative;
  margin-right: 0;

  .info {

    .title {
      margin-bottom: 0;

      div {
        display: inline-block;
        margin-right: vwRel(7);
        white-space: nowrap;
      }

      .letter {
        display: inline-block;
      }
    }

    a {
      text-decoration: none;
    }

    .link-label {
      color: $white;
      padding-left: px(3);
    }
  }
}



